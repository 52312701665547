
  const userEditorScriptEntry = { editorReady() {} }
  const editorScriptEntry = userEditorScriptEntry;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  

    var createExperiments = null;
    var createWidgetExperiments = null;
    

  
import { createHttpClient } from '@wix/yoshi-flow-editor/runtime/esm/controller/httpClientProp';
    


  import { createOwnerBILogger as biLogger } from '/home/builduser/work/63f463de1bc2bc5f/packages/members-area-notifications-preferences/target/generated/bi/createBILogger.ts';

  
  import { editorReadyWrapper, onEventWrapper, createFlowAPIFabric, exportsWrapper, getAppManifestWrapper } from '@wix/yoshi-flow-editor/runtime/esm/editorScript.js';

  let editorReady = editorScriptEntry.editorReady;
  const onEvent = onEventWrapper({ onEvent: editorScriptEntry.onEvent });
  const sentryConfig = {
    DSN: 'https://ed436f5053144538958ad06a5005e99a@sentry.wixpress.com/6142',
    id: 'undefined',
    projectName: 'members-area-notifications--0j',
    teamName: 'ping',
    errorMonitor: true,
  };
  const experimentsConfig = {"scopes":["test"],"centralized":true};
  const translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/63f463de1bc2bc5f/packages/members-area-notifications-preferences/src/assets/locales/messages_en.json","availableLanguages":["en","uk"]};
  const defaultTranslations = {"app.widget.title":"Notification Settings","app.widget.subtitle":"Choose the notifications you want to get","app.widget.PROMOTIONAL.title":"Marketing Emails","app.widget.TRANSACTIONAL.title":"Transactional Emails ","app.widget.marketing-emails.subscription.title":"Subscribed to All Marketing Emails","app.widget.marketing-emails.subscription.subscribe":"Subscribe","app.widget.marketing-emails.subscription.unsubscribe":"Unsubscribe","app.settings.label":"Settings","app.settings.tabs.design":"Design","app.settings.tabs.design.text-color.title":"Text color","app.settings.tabs.design.title-font.title":"Title text","app.settings.tabs.design.primary-font.title":"Body text","app.settings.tabs.design.button-section.title":"Button style","app.settings.tabs.design.button-text-color.title":"Button text color","app.settings.tabs.design.button-opacity-and-color.title":"Button opacity and color","app.settings.tabs.design.background-section.title":"Backgrounds and borders","app.settings.tabs.design.background-color.title":"Background opacity and color","app.settings.tabs.design.border-color.title":"Border opacity and color","app.settings.tabs.design.border-width.title":"Border width"};
  const createFlowAPI = createFlowAPIFabric({
    sentryConfig,
    experimentsConfig,
    translationsConfig,
    defaultTranslations,
    biLogger,
    shouldUseEssentials: true,
    artifactId: 'members-area-notifications-preferences',
    appDefId: '14f25dc5-6af3-5420-9568-f9c5ed98c9b1',
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: 'assets/locales',
  });

  const useAPIsFromExports = typeof editorScriptEntry.exports === "function";
  if (editorReady) {
    editorReady = editorReadyWrapper({
      editorReady,
      createFlowAPI,
      shouldSkipAPIOverrides: useAPIsFromExports,
    });
    if (false) {
      const hot = require('@wix/yoshi-flow-editor/build/cjs/wrappers/editorScriptHot.js').hot;
      editorReady = hot(module, editorReady)
    }
  }
  export { editorReady };
  export { onEvent };
  export const exports = useAPIsFromExports ? exportsWrapper(editorScriptEntry.exports) : editorScriptEntry.exports;
  export const getAppManifest = editorScriptEntry.getAppManifest ? getAppManifestWrapper(editorScriptEntry.getAppManifest, createFlowAPI) : undefined;
  export const handleAction = editorScriptEntry.handleAction;
  
